.modalAddCalendarPlus{
    align-self: center;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 6px;
    bottom: 50px;
    background-color: white;
    z-index: 99999;
    height: 100vh;
    padding: 15px;
    float: right;
    width: 46%;
    h1, h2, h3,h4,h5,
    .h1,.h2,.h3,.h4,.h5 
    {
        line-height: 1.5;
        font-weight: 400;
        color: black;
    }
    .bg-primary {
        background: #FBD74D !important;
    }
    .heading-section {
        font-size: 27px;
    }
    .addModalPlusCalendar {
        width: 80%;
        margin: 0 auto;
        margin-top: 10%;
        form {
            display: block;
            margin-top: 35px;
            font-family: Lato;
            margin-bottom: 50px;
            margin: 14px;
            .rezervuesi {
                width: 95%;
                .searchButton{
                    border-radius: 5px;
                    border: 2px #FBD74D solid;
                    transition: 0.3s ease-in-out;
                    background-color: #FBD74D;
                    color: black;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
            .form-input {
                .live-data-search-wrapper {
                    position: relative;
                    .live-search-wrapper {
                        display: flex;
                        position: absolute;
                        max-height: 300px;
                        background-color: white;
                        margin-top: 10px;
                        border-radius: 0px 0px 10px 10px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                        .fetched-speciality-wrapper {
                            width: 100%;
                            overflow: auto;
                            border-right: 1px solid #E0E1E1;
                            overflow-x: hidden;
                            padding: 10px 20px;
                            p {
                                font-size: 15px;
                                font-weight: 700;
                                color: #06013B;
                              }
                            .fetched-speciality {
                                background-color: white;
                                width: 100%;
                                cursor: pointer;
                                padding: 5px 10px;
                                &:hover 
                                {
                                    background-color: #E0E1E1;
                                }
                            }
                        }
                        .fetched-doctor-wrapper {
                            width: 100%;
                            overflow: auto;
                            border-right: 1px solid #E0E1E1;
                            overflow-x: hidden;
                            padding: 10px 20px;
                            .fetched-doctor {
                                background-color: white;
                                padding: 5px 10px;
                                width: 100%;
                                cursor: pointer;
                                &:hover 
                                {
                                    background-color: #E0E1E1;
                                }
                            }
                            p {
                                font-size: 15px;
                                font-weight: 700;
                                color: #06013B;
                              }
                            .docterinfo-wrapper {
                                display: flex;
                                vertical-align: middle;
                                .doc-image-wrapper {
                                    padding-right: 10px;
                                    .doc-image-round {
                                        width: 35px;
                                        height: 35px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }
                                .doc-maininfo-wrapper {
                                    .speciality-lable {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .email_input {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                    padding: 10px;
                    &::-webkit-input-placeholder {
                        color: black;
                    }
                    &:hover,
                    &:focus {
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        border-color: rgb(238, 198, 124);
                    }
                }
            }
            .buttoni-Plus-Calendar {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: #FBD74D;
                color: black;
                font-weight: 600;
                font-size: 17px;
                &:hover
                {
                    width: 100%;
                    color: white;
                }
            }
      
            .selectCustomerModalPlus{
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: 0.3s ease-in-out;
                color: black;
              
            }
        }
    }
}
@media only screen and (max-width: 750px) {
    .modalAddCalendarPlus{
      width: 100%;
        .loginFormAdd {
            left: 0%;
            right: 0%;
            padding: 0px;
            width: 94%;
        }
        form{
            .rezervuesi{
                width: 100% ; 
                .rezervuesiCol{
                    padding-right: 0px !important;
                }
            }
        }
    }
}
