
@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.shtoKartelenModal {
    animation-name: fade-in;
    animation-duration: .3s;
    animation-timing-function:step-start;
    // animation-direction:alternate;
    align-self: center;
    overflow: auto;
    position: relative;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 99999;
    height: 100vh;
    padding: 15px;
    /* float: right; */
    /* left: 0; */
    right: 0;
    width: 46%;
    position: fixed;
    justify-content: end;


    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
        line-height: 1.5;
        font-weight: 400;

        color: black;
    }

    .bg-primary {
        background: #FBD74D !important;
    }

    .heading-section {
        font-size: 27px;
    }

    .closeAddModal {
        position: absolute;
        right: 14px;
        top: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: unset;
        color: white;
        font-size: 20px;
        background: #FBD74D;
        z-index: 9999999;

    }

    .modalAddKartel {
        width: 80%;
        margin: 0 auto;

        form {
            // width: 80%;

            display: block;
            margin-top: 35px;
            font-family: Lato;
            margin-bottom: 50px;
            margin: 14px;


            .form-input {

                .email_input {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 2px #FBD74D solid;
                    padding: 10px;


                    &::-webkit-input-placeholder {
                        color: black;
                    }

                    &:hover,
                    &:focus {
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        border-color: rgb(238, 198, 124);
                    }
                }
            }

            .button_forgot {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: .3s ease-in-out;
                background-color: #FBD74D;
                color: white;
                font-weight: 600;

                &:hover {
                    width: 100%;
                    color: white;
                }
            }

            .selectCustomer {
                width: 100%;
                border-radius: 5px;
                border: 2px #FBD74D solid;
                padding: 10px;
                transition: 0.3s ease-in-out;
                /* background-color: #FBD74D; */
                color: black;

            }
        }
    }

    // .addModal-wrapper {

    //     .login-wrap {
    //         width: 60%;
    //         margin: 0 auto;



    //         .form-group {
    //             position: relative;


    //         }

    //         .field-icon {
    //             position: absolute;
    //             top: 50%;
    //             right: 15px;
    //             transform: translateY(-50%);
    //             color: black;
    //             cursor: pointer;
    //         }

    //         .form-control {
    //             background: transparent;
    //             border: none;
    //             height: 50px;
    //             color: black;
    //             border: 1px solid #FBD74D;
    //             border-radius: 12px;
    //             padding-left: 20px;
    //             padding-right: 20px;

    //             &::-webkit-input-placeholder {
    //                 color: black;
    //             }


    //             &:hover,
    //             &:focus {
    //                 background: transparent;
    //                 outline: none;
    //                 box-shadow: none;
    //                 border-color: rgb(238, 198, 124);
    //             }


    //         }

    //         .btn {
    //             margin: 20px 0px 12px 0px;
    //             cursor: pointer;
    //             box-shadow: none !important;
    //             font-size: 15px;
    //             text-transform: uppercase;

    //             &:hover,
    //             &:active,
    //             &:focus {
    //                 outline: none;
    //             }

    //             &.btnLogin {
    //                 background: #FBD74D !important;
    //                 // border: 1px solid#FBD74D !important;
    //                 color: rgb(255, 255, 255) !important;

    //                 &:hover {
    //                     // border: 1px solid#FBD74D;
    //                     background: rgb(255, 199, 95) !important;
    //                 }


    //             }
    //         }
    //     }
    // }
}

@media only screen and (max-width: 750px) {
    .shtoKartelenModal{
        width: 100%;
        .addModal-wrapper{
            margin-top: 21%;
        }
  

}
}
