.myCustomHeight {
    position: absolute;
    top: 6%;
    padding: 25px;
}
.addNewAppointment {
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 1;
    border-radius: 50%;
    background-color: #FFF;
    color: #FBD74D;
    border: unset;
    padding: 22px 9px;
    border-radius: 50% !important;
    border: 4px #FBD74D solid;
    transition: .3s ease-in-out;
    font-size: 42px;
    line-height: 0px;
    &:hover {
        text-decoration: none;
        background-color: #FBD74D;
        border: 5px #FFF solid;
        color: #FFF;
        filter: drop-shadow(0 0.25rem 0.75rem gray);
    }
}
.calendar {
    .rbc-day-slot,
    .rbc-event {
        border: none !important;
    }
}
.rbc-event-label {
    display: none !important;
}
.selectCustomerName {
    padding: 6px;
    border: 2px #579bd6 solid;
    margin-bottom: 5px;
    border-radius: 5px;

}
@media only screen and (max-width: 750px) {
    .rbc-event-content {
        font-size: 10px;
    }

    .rbc-events-container {
        width: 94%;
    }
}